import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import './App.scss';

import NavBar from './components/navbar';


const AsyncLandingPage = Loadable({
    loader: () => import(/* webpackChunkName: "LandingPageChunk" */ './components/landing_page'),
    loading: () => <div>loading...</div>,
    modules: ['LandingPageChunk'],
});

const AsyncAboutPage = Loadable({
    loader: () => import('./components/about'),
    loading: () => <div>loading...</div>,
    modules: ['AboutPageChunk'],
});

const AsyncWorkPage = Loadable({
    loader: () => import('./components/work'),
    loading: () => <div>loading...</div>,
    modules: ['WorkPageChunk'],
});

const AsyncContactPage = Loadable({
    loader: () => import('./components/contact'),
    loading: () => <div>loading...</div>,
    modules: ['ContactPageChunk'],
});

const AsyncEckhartTolleNowPage = Loadable({
    loader: () => import('./components/work/eckhart_tolle_now'),
    loading: () => <div>loading...</div>,
    modules: ['EckhartTolleNowPageChunk'],
});

const AsyncInkshufflePage = Loadable({
    loader: () => import('./components/work/inkshuffle'),
    loading: () => <div>loading...</div>,
    modules: ['InkshufflePageChunk'],
});

const AsyncHirebenchPage = Loadable({
    loader: () => import('./components/work/hirebench'),
    loading: () => <div>loading...</div>,
    modules: ['HirebenchPageChunk'],
});

const AsyncIssemblePage = Loadable({
    loader: () => import('./components/work/issemble'),
    loading: () => <div>loading...</div>,
    modules: ['IssemblePageChunk'],
});

const AsyncLiivMdPage = Loadable({
    loader: () => import('./components/work/liiv_md'),
    loading: () => <div>loading...</div>,
    modules: ['LiivMdPageChunk'],
});

const AsyncBridlewoodCommunityPage = Loadable({
    loader: () => import('./components/work/bridlewood_community'),
    loading: () => <div>loading...</div>,
    modules: ['BridlewoodCommunityPageChunk'],
});


const AsyncFooter = Loadable({
    loader: () => import('./components/footer'),
    loading: () => <div>...</div>,
    modules: ['AsyncFooterChunk'],
});


const App = () => {
  return (
    <div>
      <NavBar/>
      <Route exact path="/" component={AsyncLandingPage}/>
      <Route path="/about" component={AsyncAboutPage}/>
      <Route path="/work/eckhart-tolle-now" component={AsyncEckhartTolleNowPage} exact/>
      <Route path="/work/inkshuffle" component={AsyncInkshufflePage} exact/>
      <Route path="/work/hirebench" component={AsyncHirebenchPage} exact/>
      <Route path="/work/issemble" component={AsyncIssemblePage} exact/>
      <Route path="/work/liiv-md" component={AsyncLiivMdPage} exact/>
      <Route path="/work/medical-practice-management-software-liivmd" component={AsyncLiivMdPage} exact/>
      <Route path="/work/bridlewood-community" component={AsyncBridlewoodCommunityPage} exact/>
      <Route path="/work" component={AsyncWorkPage} exact/>
      <Route path="/contact" component={AsyncContactPage}/>
      <AsyncFooter/>
    </div>
  );
};

export default App;
