import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './utils/scrollToTop';

import App from './App';
// import registerServiceWorker from './registerServiceWorker';

const ClientApp = (
  <BrowserRouter>
    <ScrollToTop>
      <App/>
    </ScrollToTop>
  </BrowserRouter>
);

Loadable.preloadReady().then(() => {
    ReactDOM.hydrate(
      ClientApp,
      document.getElementById('root')
    );
});

// registerServiceWorker();
