import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import cx from 'classnames';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openHamburger: true,
    };
  };

  _toggleHamburger() {
    this._setOpenHamburger(!this.state.openHamburger, function() {
      this._openNavDropdown(!this.state.openHamburger);
    });
  };

  _openNavDropdown(isOpen) {
    if (isOpen) {
      document.getElementById('root').classList.add('menu-active');
    } else {
      document.getElementById('root').classList.remove('menu-active');
    }
  };

  _routerOnClick() {
    this._setOpenHamburger(true, () => {})
    this._openNavDropdown(false);
  };

  _setOpenHamburger(openHamburger, callback) {
    this.setState({ openHamburger }, callback);
  };

  getYear() {
    return new Date().getFullYear();
  };

  render() {
    const {
      location: { pathname },
    } = this.props;

    return (
      <header id="site-header">
        <div className="container is-flex">
          <Link to="/" className="router-link-active" title="Home">
            <HeaderLogo/>
          </Link>

          <i className={`material-icons is-mobile ${this.state.openHamburger ? 'menu' : 'toggle-close'}`}
            onClick={this._toggleHamburger.bind(this)}>
            {this.state.openHamburger ? 'subject' : 'close'}
          </i>

          <div className="nav-wrapper ml-auto">
            <div className="nav-container">
              <div className="nav-header nav-header-custom">
                <Link to="/" className="router-link-active" title="Home" itemProp="url">
                  <NavLogo/>
                </Link>
              </div>

              <div className="nav-content">
                <nav className="site-navigation">
                  <Link to="/" className={cx({
                    'router-link': true,
                    'router-link-exact-active': pathname === '/',
                    'router-link-active': pathname === '/'
                  })} title="Home" onClick={this._routerOnClick.bind(this)}>
                  <span>Home</span>
                  </Link>

                  <Link to="/about" className={cx({
                    'router-link': true,
                    'router-link-exact-active': pathname === '/about',
                    'router-link-active': pathname === '/about'
                  })} title="About" onClick={this._routerOnClick.bind(this)}>
                    <span>About</span>
                  </Link>

                  <Link to="/work" className={cx({
                    'router-link': true,
                    'router-link-exact-active': pathname === '/work',
                    'router-link-active': pathname === '/work'
                  })} title="Work" onClick={this._routerOnClick.bind(this)}>
                    <span>Work</span>
                  </Link>

                  <Link to="/contact" className={cx({
                    'router-link': true,
                    'router-link-exact-active': pathname === '/contact',
                    'router-link-active': pathname === '/contact'
                  })} title="Contact" onClick={this._routerOnClick.bind(this)}>
                    <span>Contact</span>
                  </Link>
                </nav>

                <div className="nav-contact">
                  <p>
                    <strong>Email</strong>
                    <a href="mailto:hello@pullerits.com">hello@pullerits.com</a>
                  </p>
                  <p>
                    <strong>Phone</strong>
                    <a href="tel:+18886969631">+1 888 696 9631</a>
                  </p>
                </div>
              </div>

              <div className="nav-footer">
                <span>&copy; {this.getYear()} Pullerits Group Inc.</span>
              </div>
            </div>
            <div className="nav-overlay"></div>
          </div>
        </div>
      </header>
    )
  }
}

const HeaderLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 400" className="injected-svg site-logo inject">
      <title>pullerits-logo</title>
      <path fill="#0d3068" d="M179.08,127.72c9,12.28,10.29,36.54,4.32,73.4-6,37.87-14.95,63.11-27.24,76.06-12.29,12.62-33.55,18.93-64.1,18.93q-25.41,0-41.85-2l-10,61.45c-.33,3.65-2,5.31-5.64,5.31H4.36c-3,0-4.65-1.66-4.32-5.31l37.2-233.83c.66-3.66,3-6,7-6.64a454.25,454.25,0,0,1,71.75-6C149.51,109.12,170.44,115.43,179.08,127.72ZM73.78,144,55.51,258.92a306.84,306.84,0,0,0,35.87,2c18.27,0,30.23-3.65,36.2-11,6.31-7.3,11.3-23.25,15.28-47.5,4.32-22.92,4.65-38.2,1-46.17C140.21,148,129.58,144,112,144Z"></path>
      <path fill="#0d3068" d="M384.92,96.44q5,0,4,5L360.37,285.16c-.66,4.32-2.32,4.65-5.64,5.31-18.93,3.66-44.18,5.65-75.07,5.65-31.56.33-49.16-3-60.45-13.28-9.64-11.63-11-30.89-5.65-65.77l15.95-101.31c.66-3.32,2.66-5,6.31-5h29.89c3.32,0,4.65,1.66,4.32,5l-15.28,94c-3,20.26-3,31.56-1.66,39.53,4.65,11.62,13,11.62,32.55,12h38.2l22.92-145.48c.66-3.32,2.24-4.45,5.56-6.07Z"></path>
      <path fill="#0d3068" d="M474.48,40.36c3,0,4.66,1.66,4.32,5L439.94,289.48c-.33,3.32-2.32,5-5.64,5H404.06c-3,0-4.65-1.66-4.32-5L436.45,59.41c.33-3.32,2.32-4.43,5.64-5.8Z"></path>
      <path fill="#0d3068" d="M720.88,127.05c8.3,12,10,35.87,4.32,72.08l-1.66,10c-1,6.64-3.32,9-12.62,9H606c-2.32,17.6-1,29.23,3.32,34.87s14.62,8.3,30.89,8.3h56.47c3.32,0,4.65,1.66,4.32,5l-3.32,20.59c-.34,3-2,5-5.65,5.65-16.28,2.32-36.2,3.66-59.79,3.66-64.44,0-76.39-18.6-64.77-93.33,6-37.54,15.28-62.78,27.57-75.06s34.54-18.6,66.77-18.6C693,109.12,712.58,115.1,720.88,127.05ZM624.23,153.3q-9.47,9-14,35.87h76.06q4-26.9-2-35.87c-4-6.31-13.28-9.3-27.9-9.3C641.17,144,630.54,147,624.23,153.3Z"></path>
      <path fill="#0d3068" d="M866.75,112.78q4.48,1.49,4,6L867.41,139q-.5,5.48-6,5H831.21c-20.26,0-26.57,3.66-28.9,17.94L782.05,289.48c-.33,3.32-2.32,5-5.65,5H746.18c-3,0-4.65-1.66-4.32-5l21.92-137.84c3-18.6,11.63-30.89,24.91-36.2s22.59-6.31,38.2-6.31C845.49,109.12,858.78,110.45,866.75,112.78Z"></path>
      <path fill="#0d3068" d="M923.7,110.78q5,0,4,5L900.12,289.48c-.33,3.32-2.32,5-5.64,5H864.25c-3,0-4.65-1.66-4.32-5L887.5,115.76q1-5,6-5Z"></path>
      <path fill="#0d3068" d="M1018.29,60c4-1,5.65.66,4.66,4.65l-7.31,46.5h32.89c3.65,0,5,1.66,4.65,5L1049.51,137c-.33,3.66-2.32,5.31-6,5.31h-32.88L994.7,243.64c-2.32,14.62,1.66,17.6,17.94,17.6h14.62c3.65,0,5.31,1.66,5,4.66l-3.32,21.92c-1,3.32-3,5.31-6.3,6a126.76,126.76,0,0,1-23.92,2.32c-25.91.33-52.48-1-46.16-40.52l17.93-113.27H951.53c-3.32,0-5-1.66-4.65-5.31l3.32-20.93q.51-5,6-5h19.26l6-38.2a6.34,6.34,0,0,1,5.32-5.32Z"></path>
      <path fill="#0d3068" d="M1196.18,112.78c3,.66,4.32,2.32,3.66,5.31l-3,19.6q-.5,5-6,5h-60.79c-14.95,0-22.92,5-24.58,14.61l-.66,4c-1,8.64,4,15.28,15.28,19.6l41.19,15.94c25.24,10.3,30.22,24.91,26.24,48.5l-.66,4.32c-5.65,35.87-29.56,46.5-76.39,46.5-21.59,0-42.85-1-64.1-3-3.66-.33-5-2-4.32-5l3.32-20.6c.33-3.65,2.32-5.31,6-5.31h64.44c22.59,0,29.89-2.66,31.56-14.28l.66-3.32c1.33-8.3-2.32-13.62-15.94-18.93l-41.52-16.61c-20.59-8-28.89-24.25-25.24-48.83l1.32-6.64c5-33.55,32.22-44.51,78.39-44.51A345.19,345.19,0,0,1,1196.18,112.78Z"></path>
      <path fill="#42c2f3" d="M901.12,40.47a3,3,0,0,1,1,.2l38.71,19.05a1.32,1.32,0,0,1,.9,1.71L940.64,69a2.77,2.77,0,0,1-1.51,1.71L894.41,89.71a3.11,3.11,0,0,1-1.1.2H892c-.6,0-.8-.3-.7-.8l7.62-47.84c0-.5.3-.8.9-.8Z"></path>
      <path d="M567.94,350l3.23,3.82c5.44,6.43,10.95,12.8,16.29,19.31,3.52,4.28,4.19,9.15,1.64,14.12a12.5,12.5,0,0,1-12.29,7.23,13.21,13.21,0,0,1-9.81-5.16q-8.81-10.42-17.66-20.81c-.39-.46-.81-.88-1.4-1.52-.61,1.13-1.16,2.07-1.64,3q-6.4,13.17-12.79,26.34c-1.64,3.38-4.46,4.47-7.93,3.06a9.39,9.39,0,0,1-5.63-6.62q-4.62-16.48-9.26-33-9.1-32.33-18.19-64.67a21,21,0,0,1-.73-3.6c-.7-7.18,4.25-11.38,11.2-9.35a47,47,0,0,1,6.59,2.81q44,20.63,88,41.27c3.14,1.47,5.39,3.57,6.13,7.07a5.66,5.66,0,0,1-4.19,7.1q-14,4.16-28.07,8.28C570.35,349.07,569.28,349.49,567.94,350Z"></path>
      <path fill="#42c2f3" d="M561.54,0c3,0,4.66,1.66,4.32,5L527,249.12c-.33,3.32-2.32,5-5.64,5H491.12c-3,0-4.65-1.66-4.32-5L523.51,19.05c.33-3.32,2.32-4.43,5.64-5.8Z"></path>
      </svg>
  );
};

const NavLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 400" className="injected-svg nav-logo inject">
      <title>pullerits-logo</title>
      <path fill="#0d3068" d="M179.08,127.72c9,12.28,10.29,36.54,4.32,73.4-6,37.87-14.95,63.11-27.24,76.06-12.29,12.62-33.55,18.93-64.1,18.93q-25.41,0-41.85-2l-10,61.45c-.33,3.65-2,5.31-5.64,5.31H4.36c-3,0-4.65-1.66-4.32-5.31l37.2-233.83c.66-3.66,3-6,7-6.64a454.25,454.25,0,0,1,71.75-6C149.51,109.12,170.44,115.43,179.08,127.72ZM73.78,144,55.51,258.92a306.84,306.84,0,0,0,35.87,2c18.27,0,30.23-3.65,36.2-11,6.31-7.3,11.3-23.25,15.28-47.5,4.32-22.92,4.65-38.2,1-46.17C140.21,148,129.58,144,112,144Z"></path>
      <path fill="#0d3068" d="M384.92,96.44q5,0,4,5L360.37,285.16c-.66,4.32-2.32,4.65-5.64,5.31-18.93,3.66-44.18,5.65-75.07,5.65-31.56.33-49.16-3-60.45-13.28-9.64-11.63-11-30.89-5.65-65.77l15.95-101.31c.66-3.32,2.66-5,6.31-5h29.89c3.32,0,4.65,1.66,4.32,5l-15.28,94c-3,20.26-3,31.56-1.66,39.53,4.65,11.62,13,11.62,32.55,12h38.2l22.92-145.48c.66-3.32,2.24-4.45,5.56-6.07Z"></path>
      <path fill="#0d3068" d="M474.48,40.36c3,0,4.66,1.66,4.32,5L439.94,289.48c-.33,3.32-2.32,5-5.64,5H404.06c-3,0-4.65-1.66-4.32-5L436.45,59.41c.33-3.32,2.32-4.43,5.64-5.8Z"></path>
      <path fill="#0d3068" d="M720.88,127.05c8.3,12,10,35.87,4.32,72.08l-1.66,10c-1,6.64-3.32,9-12.62,9H606c-2.32,17.6-1,29.23,3.32,34.87s14.62,8.3,30.89,8.3h56.47c3.32,0,4.65,1.66,4.32,5l-3.32,20.59c-.34,3-2,5-5.65,5.65-16.28,2.32-36.2,3.66-59.79,3.66-64.44,0-76.39-18.6-64.77-93.33,6-37.54,15.28-62.78,27.57-75.06s34.54-18.6,66.77-18.6C693,109.12,712.58,115.1,720.88,127.05ZM624.23,153.3q-9.47,9-14,35.87h76.06q4-26.9-2-35.87c-4-6.31-13.28-9.3-27.9-9.3C641.17,144,630.54,147,624.23,153.3Z"></path>
      <path fill="#0d3068" d="M866.75,112.78q4.48,1.49,4,6L867.41,139q-.5,5.48-6,5H831.21c-20.26,0-26.57,3.66-28.9,17.94L782.05,289.48c-.33,3.32-2.32,5-5.65,5H746.18c-3,0-4.65-1.66-4.32-5l21.92-137.84c3-18.6,11.63-30.89,24.91-36.2s22.59-6.31,38.2-6.31C845.49,109.12,858.78,110.45,866.75,112.78Z"></path>
      <path fill="#0d3068" d="M923.7,110.78q5,0,4,5L900.12,289.48c-.33,3.32-2.32,5-5.64,5H864.25c-3,0-4.65-1.66-4.32-5L887.5,115.76q1-5,6-5Z"></path>
      <path fill="#0d3068" d="M1018.29,60c4-1,5.65.66,4.66,4.65l-7.31,46.5h32.89c3.65,0,5,1.66,4.65,5L1049.51,137c-.33,3.66-2.32,5.31-6,5.31h-32.88L994.7,243.64c-2.32,14.62,1.66,17.6,17.94,17.6h14.62c3.65,0,5.31,1.66,5,4.66l-3.32,21.92c-1,3.32-3,5.31-6.3,6a126.76,126.76,0,0,1-23.92,2.32c-25.91.33-52.48-1-46.16-40.52l17.93-113.27H951.53c-3.32,0-5-1.66-4.65-5.31l3.32-20.93q.51-5,6-5h19.26l6-38.2a6.34,6.34,0,0,1,5.32-5.32Z"></path>
      <path fill="#0d3068" d="M1196.18,112.78c3,.66,4.32,2.32,3.66,5.31l-3,19.6q-.5,5-6,5h-60.79c-14.95,0-22.92,5-24.58,14.61l-.66,4c-1,8.64,4,15.28,15.28,19.6l41.19,15.94c25.24,10.3,30.22,24.91,26.24,48.5l-.66,4.32c-5.65,35.87-29.56,46.5-76.39,46.5-21.59,0-42.85-1-64.1-3-3.66-.33-5-2-4.32-5l3.32-20.6c.33-3.65,2.32-5.31,6-5.31h64.44c22.59,0,29.89-2.66,31.56-14.28l.66-3.32c1.33-8.3-2.32-13.62-15.94-18.93l-41.52-16.61c-20.59-8-28.89-24.25-25.24-48.83l1.32-6.64c5-33.55,32.22-44.51,78.39-44.51A345.19,345.19,0,0,1,1196.18,112.78Z"></path>
      <path fill="#42c2f3" d="M901.12,40.47a3,3,0,0,1,1,.2l38.71,19.05a1.32,1.32,0,0,1,.9,1.71L940.64,69a2.77,2.77,0,0,1-1.51,1.71L894.41,89.71a3.11,3.11,0,0,1-1.1.2H892c-.6,0-.8-.3-.7-.8l7.62-47.84c0-.5.3-.8.9-.8Z"></path>
      <path d="M567.94,350l3.23,3.82c5.44,6.43,10.95,12.8,16.29,19.31,3.52,4.28,4.19,9.15,1.64,14.12a12.5,12.5,0,0,1-12.29,7.23,13.21,13.21,0,0,1-9.81-5.16q-8.81-10.42-17.66-20.81c-.39-.46-.81-.88-1.4-1.52-.61,1.13-1.16,2.07-1.64,3q-6.4,13.17-12.79,26.34c-1.64,3.38-4.46,4.47-7.93,3.06a9.39,9.39,0,0,1-5.63-6.62q-4.62-16.48-9.26-33-9.1-32.33-18.19-64.67a21,21,0,0,1-.73-3.6c-.7-7.18,4.25-11.38,11.2-9.35a47,47,0,0,1,6.59,2.81q44,20.63,88,41.27c3.14,1.47,5.39,3.57,6.13,7.07a5.66,5.66,0,0,1-4.19,7.1q-14,4.16-28.07,8.28C570.35,349.07,569.28,349.49,567.94,350Z"></path>
      <path fill="#42c2f3" d="M561.54,0c3,0,4.66,1.66,4.32,5L527,249.12c-.33,3.32-2.32,5-5.64,5H491.12c-3,0-4.65-1.66-4.32-5L523.51,19.05c.33-3.32,2.32-4.43,5.64-5.8Z"></path>
    </svg>
  );
};

export default withRouter(NavBar);
