import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';


class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.GACode = 'UA-152709098-1';
  }

  trackGA(pathname, search) {
    ReactGA.initialize(this.GACode);
    ReactGA.pageview(pathname + search);
  }

  componentDidMount() {
    const { location: { pathname, search } } = this.props;
    if (typeof window !== 'undefined') {
      this.trackGA(pathname, search);
    }
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname: currPathname, search: currSearch } } = this.props;
    const { location: { pathname: prevPathname } } = prevProps;
    if (currPathname !== prevPathname) {
      if (typeof window !== 'undefined') {
        this.trackGA(currPathname, currSearch);
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ScrollToTop.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(ScrollToTop);
